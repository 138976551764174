import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { backendUrl } from '../config';

export const fetchData = createAsyncThunk('data/fetchData', async () => {
  const response = await fetch(`${backendUrl}/api/data`);
  console.log(response);
  console.log(backendUrl);
  const data = await response.json();
  return data.message;
});

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    value: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.value = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default dataSlice.reducer;
