import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from './store/dataSlice';

function App() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.value);
  const status = useSelector((state) => state.data.status);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchData());
    }
  }, [status, dispatch]);

  return (
    <div>
      <h1>Frontend App</h1>
      <p>{status === 'loading' ? "Loading..." : data}</p>
    </div>
  );
}

export default App;
